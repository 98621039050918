import { CancelButton, Link } from '@silinfo/front-end-template';

const BackToLoginButton = () => (
    <Link to='/'>
        <CancelButton fullWidth size='large'>
            Vissza a bejelentkezésre
        </CancelButton>
    </Link>
);

export default BackToLoginButton;
