import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { endpoints } from '../utils/endpoints';
import TextField from './TextField';

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        fontSize: 'medium',
        borderBottom: '1px solid white',
        '&:hover': {
            borderBottom: '1px solid ' + theme.palette.primary.main,
            cursor: 'pointer',
        },
        textDecoration: 'none',
    },
}));

function Opener({
    setOpen,
}: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classes = useStyles();
    return (
        <Grid container justifyContent='flex-end'>
            <Grid item>
                <div className={classes.link} onClick={() => setOpen(true)}>
                    Elfelejtett jelszó
                </div>
            </Grid>
        </Grid>
    );
}

export default function ForgotPassword({
    setError,
    formEmail,
}: {
    setError: (value: string) => void;
    formEmail: string;
}) {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    useEffect(() => {
        open && setEmail(formEmail);
        setSuccess(false);
    }, [formEmail, open]);

    const handleSubmit = () => {
        setLoading(true);
        axios
            .post(endpoints.forgottenPassword, {
                email,
            })
            .then(() => setSuccess(true))
            .catch(() => setError('Hiba a feldolgozás során'))
            .finally(() => setLoading(false));
    };

    const isError = (): string => {
        if (!email) return 'Kötelező kitölteni!';
        if (!regex.test(email)) return 'Hibás e-mail formátum!';
        return '';
    };

    return (
        <>
            <Opener setOpen={setOpen} />
            <Dialog
                open={open}
                fullWidth
                onBackdropClick={() => setOpen(false)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogTitle>Elfelejtett jelszó</DialogTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogContent>
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    {success
                                        ? 'Sikeres mentés! Amennyiben helyes e-mail címet adtál meg, kérjük, ellenőrizd a postafiókodat, ahová további információkat küldtünk!'
                                        : 'Kérjük, add meg az e-mail címedet!'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    {!success && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label='E-mail cím'
                                                name='email'
                                                margin='normal'
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                error={!!isError()}
                                                helperText={isError()}
                                                size='medium'
                                                value={email}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            {!success && (
                                <SubmitButton
                                    disabled={!!isError()}
                                    onClick={handleSubmit}
                                    loading={loading}
                                >
                                    Mehet
                                </SubmitButton>
                            )}
                            <CancelButton onClick={() => setOpen(false)}>
                                Vissza
                            </CancelButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}
