import { LoadingButton } from "@mui/lab";
import Grid from "@mui/material/Grid";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Field, Form, Formik } from "formik";
import React, { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import api from "../api";
import "../App.scss";
import { removeLayoutType, setIsNewLogin, setJWT } from "../utils/cookies";
import { endpoints } from "../utils/endpoints";
import { redirectToTarget } from "../utils/redirectToTarget";
import ForgotPassword from "./ForgotPassword";
import TextField from "./TextField";
import ExpiredPasswordModal from "./ExpiredPasswordModal";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Hibás e-mail formátum")
    .required("Kötelező megadni"),
  password: Yup.string().required("Kötelező megadni"),
});

class LoginFormClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      expiredPasswordModalOpen: false,
      ref: createRef(),
    };
  }

  handleSendPasswordResetFromAdminEmail = (email: string) => {
    axios
      .post(endpoints.forgottenPassword, {
        email,
      })
      .then(() => this.setState({ expiredPasswordModalOpen: true }))
      .catch(() => this.props.setError("Hiba a feldolgozás során"));
  };

  render() {
    return (
      <>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (form) => {
          this.setState({ loading: true });
          const token = await this.state.ref.current?.executeAsync();
          api
            .post(endpoints.login, { ...form, "g-recaptcha-response": token })
            .then((response: AxiosResponse) => {
              const { tenants } = response.data;  
              removeLayoutType();
              const setLoading = (value: boolean) => this.setState({ loading: value });
              redirectToTarget(tenants, setLoading, this.props.check);
              setJWT(response.data.token || "");
              setIsNewLogin();
            })
            .catch((error: AxiosError) => {
              if (error.response?.data.status === "DISABLED") {
                this.props.setError(
                  "A megadott felhasználó számára nem elérhető a rendszer."
                );
              } else if (error.response?.data.status === "CAPTCHA ERROR") {
                this.props.setError("Captcha azonosítás sikertelen.");
              } else if (error.response?.data.message) {
                this.handleSendPasswordResetFromAdminEmail(form.email);
              } else {
                this.props.setError(
                  error.response?.status === 401
                    ? "Hibás bejelentkezési adatok."
                    : "Hiba történt."
                );
              }
            }).finally(() => {
              this.setState({ loading: false });
              this.state.ref.current?.reset();
            });
        }}
        validationSchema={LoginSchema}
      >
        {({ errors, touched, values }) => (
          <Form style={{ width: "100%" }}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Field as="div" field="email">
                  <TextField
                    required
                    name="email"
                    label="E-mail cím"
                    margin="normal"
                    error={!!(errors.email && touched.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    autoFocus
                  />
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field as="div" field="password">
                  <TextField
                    required
                    name="password"
                    type="password"
                    label="Jelszó"
                    margin="normal"
                    error={!!(errors.password && touched.password)}
                    helperText={touched.password && errors.password}
                    fullWidth
                  />
                </Field>
              </Grid>
              <Grid item xs={12}>
                <ForgotPassword setError={this.props.setError} formEmail={values.email} />
              </Grid>
              {this.state.expiredPasswordModalOpen && (
                <ExpiredPasswordModal
                  isOpen={this.state.expiredPasswordModalOpen}
                  onClose={() => this.setState({ expiredPasswordModalOpen: false })}
                ></ExpiredPasswordModal>
              )}
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  color="secondary"
                  variant="contained"
                  size="large"
                  type="submit"
                  disabled={
                    !!(errors.email || errors.password) ||
                    !(values.email && values.password)
                  }
                  sx={{
                    padding: "8px",
                    height: 56,
                  }}
                  loading={this.state.loading}
                >
                  Bejelentkezés
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {process.env.REACT_APP_RECAPTCHA_SITE_KEY && process.env.REACT_APP_RECAPTCHA_SITE_KEY !== 'null'  && 
        <ReCAPTCHA
        size="invisible"
        ref={this.state.ref}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      }
      </>
    );
  }
}

// ha a későbbiekben gondot okozna a class komponens, akkor lehet az alábbit használni
// class komponens a recaptcha miatt lett használva, mert csak azzal működik
//
// const LoginForm = ({
//   setError,
//   check,
// }: {
//   setError: (value: string) => void;
//   check: () => void;
// }) => {
//   const [loading, setLoading] = useState(false);
//   const [expiredPasswordModalOpen, setExpiredPwModalOpen] =
//     useState<boolean>(false);
//   const ref = createRef<ReCAPTCHA>();

//   const handleSendPasswordResetFromAdminEmail = (email: string) => {
//     axios
//       .post(endpoints.forgottenPassword, {
//           email,
//       })
//       .then(() => setExpiredPwModalOpen(true))
//       .catch(() => setError('Hiba a feldolgozás során'));
//   };

//   console.log(ref.current, '???')

//   return (
//     <>
//     <Formik
//       initialValues={{ email: "", password: "" }}
//       onSubmit={async (form) => {
//         setLoading(true);
//         const token = await ref.current?.executeAsync();
//         api
//           .post(endpoints.login, { ...form, "g-recaptcha-response": token })
//           .then((response: AxiosResponse) => {
//             removeLayoutType();
//             redirectToTarget(setLoading, check);
//             setJWT(response.data.token || "");
//             setIsNewLogin();
//           })
//           .catch((error: AxiosError) => {
//             if (error.response?.data.status === "DISABLED") {
//               setError(
//                 "A megadott felhasználó számára nem elérhető a rendszer."
//               );
//             } else if (error.response?.data.status === "CAPTCHA ERROR") {
//               setError("Captcha azonosítás sikertelen.");
//             } else if (error.response?.data.message) {
//               handleSendPasswordResetFromAdminEmail(form.email);
//             } else {
//               setError(
//                 error.response?.status === 401
//                   ? "Hibás bejelentkezési adatok."
//                   : "Hiba történt."
//               );
//             }
//           }).finally(() => {
//             setLoading(false);
//           });
//       }}
//       validationSchema={LoginSchema}
//     >
//       {({ errors, touched, values }) => (
//         <Form style={{ width: "100%" }}>
//           <Grid item container spacing={2}>
//             <Grid item xs={12}>
//               <Field as="div" field="email">
//                 <TextField
//                   required
//                   name="email"
//                   label="E-mail cím"
//                   margin="normal"
//                   error={!!(errors.email && touched.email)}
//                   helperText={touched.email && errors.email}
//                   fullWidth
//                   autoFocus
//                 />
//               </Field>
//             </Grid>
//             <Grid item xs={12}>
//               <Field as="div" field="password">
//                 <TextField
//                   required
//                   name="password"
//                   type="password"
//                   label="Jelszó"
//                   margin="normal"
//                   error={!!(errors.password && touched.password)}
//                   helperText={touched.password && errors.password}
//                   fullWidth
//                 />
//               </Field>
//             </Grid>
//             <Grid item xs={12}>
//               <ForgotPassword setError={setError} formEmail={values.email} />
//             </Grid>
//             {expiredPasswordModalOpen && (
//               <ExpiredPasswordModal
//                 isOpen={expiredPasswordModalOpen}
//                 onClose={() => setExpiredPwModalOpen(false)}
//               ></ExpiredPasswordModal>
//             )}
//             <Grid item xs={12}>
//               <LoadingButton
//                 fullWidth
//                 color="secondary"
//                 variant="contained"
//                 size="large"
//                 type="submit"
//                 disabled={
//                   !!(errors.email || errors.password) ||
//                   !(values.email && values.password)
//                 }
//                 sx={{
//                   padding: "8px",
//                   height: 56,
//                 }}
//                 loading={loading}
//               >
//                 Bejelentkezés
//               </LoadingButton>
//             </Grid>
//           </Grid>
//         </Form>
//       )}
//     </Formik>
//       <ReCAPTCHA
//         size="invisible"
//         ref={ref}
//         sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
//       />
//     </>
//   );
// };

export default LoginFormClass;
