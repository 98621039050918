import { withStyles } from "@mui/styles";
import TxtField, { TextFieldProps } from "@mui/material/TextField";
import { Theme } from "@mui/material";

const CssTextField = withStyles((theme: Theme) => ({
  root: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
    "& label.Mui-focused": {
      color: theme.palette.secondary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}))(TxtField);

export default function TextField(props: TextFieldProps) {
  return <CssTextField variant="outlined" {...props} />;
}
