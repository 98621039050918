import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { endpoints } from "../../utils/endpoints";
import Layout from "../Layout";
import Processing from "../Processing";
import BackToLoginButton from "../ForgottenPassword/BackToLoginButton";
import Form from "./Form";
import api from "../../api";
import { Helmet } from "@silinfo/front-end-template";
import { STATUS, Status, Tenant } from "../../App";
import { redirectToTarget } from "../../utils/redirectToTarget";
import TenantSelectorActivation from "../TenantSelectorActivation";
import LoggedInViewActivation from "../LoggedInViewActivation";
import * as Sentry from '@sentry/react';

interface TokenCheckResponse {
  exists: boolean;
  isLogined: boolean;
  isValid?: boolean;
}

export default function Activation() {
  const { token } = useParams() as { token: string }; 
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<TokenCheckResponse | null>(null);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [profile, setProfile] = useState<string>("");
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [isCentralAdmin, setIsCentralAdmin] = useState(false);
  const [status, setStatus] = useState<Status>("LOADING");
  const [message, setMessage] = useState<{
    open: boolean;
    message: string;
    type: "success" | "error";
  }>({
    open: false,
    message: "",
    type: "error",
  });
  const [changed, setChanged] = useState(false);


  const getContent = () => {
    switch (status) {
      case STATUS.LOADING:
        return <Processing/>;
      case STATUS.TENANT_SELECT:
        return <TenantSelectorActivation isCentralAdmin={isCentralAdmin} tenants={tenants} setStatus={setStatus} setSelectedTenant={setSelectedTenant} />;
      case STATUS.LOGGED_IN:
        return <LoggedInViewActivation selectedTenant={selectedTenant} setStatus={setStatus} showLogOutButton={tenants.length === 1 && !isCentralAdmin}/>;
      default:
        return <></>
    }
  };

  const checkToken = useCallback(() => {
    setLoading(true);
    api
      .get(endpoints.activation + "/" + token, {})
      .then((response: AxiosResponse<TokenCheckResponse>) =>
        setState(response.data)
      )
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(
    () => setChanged((prev) => prev || message.type === "success"),
    [message.type]
  );

  useEffect(() => {
    api
      .get(endpoints.check)
      .then((response) => {

        const { firstName, lastName, tenantIds, isCentralAdmin, tenants, id, email } = response.data;  
        setProfile(lastName + " " + firstName);
        setIsCentralAdmin(isCentralAdmin);
        setTenants(tenants);
        setSelectedTenant(tenants[0] ?? null);

        Sentry.setUser({
          id: '' + id,
          email,
        });

        if (tenantIds?.length > 1 || isCentralAdmin) {
          setStatus(STATUS.TENANT_SELECT);
        }
        else {
          setStatus(STATUS.LOGGED_IN);
        }
        redirectToTarget(tenants);

        if (response) {
          setState({ exists: false, isLogined: true });
        } else {
          checkToken();
        }
      })
      .catch(() => {
        checkToken();
      })
      .finally(() => setLoading(false));

    //
  }, [checkToken]);

  const content = (): { message: string; content: JSX.Element } => {
    if (state) {
      if (state.isLogined) {
        return {
          message: "Bejelentkezve nem hajtható végre az aktiválás!",
          content: (
            <>
              <Grid item xs={12}>
                {getContent()}
              </Grid>
            </>
          ),
        };
      }
      if (state.exists) {
        if (state.isValid) {
          return {
            message: changed
              ? "Sikeres aktiváció!"
              : "A felhasználói fiók aktiválásához kérjük, adj meg egy jelszót, amivel be szeretnél lépni a rendszerbe",
            content: <Form token={token} setMessage={setMessage} />,
          };
        }

        return {
          message: "Az aktivációs link már felhasználásra került vagy lejárt",
          content: <BackToLoginButton />,
        };
      }

      return {
        message: "Az aktivációs link nem létezik",
        content: <BackToLoginButton />,
      };
    }

    return {
      message: "Hiba a feldolgozás során",
      content: <BackToLoginButton />,
    };
  };

  return (
    <Layout>
      <Helmet project="MCC" title="Aktiválás" />
      {loading ? (
        <Processing />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h5">
              {content().message}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent="center" textAlign="center">
            {content().content}
          </Grid>
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={message.open}
        autoHideDuration={4000}
        onClose={() => setMessage({ open: false, message: "", type: "error" })}
      >
        <Alert severity={message.type}>{message.message}</Alert>
      </Snackbar>
    </Layout>
  );
}
