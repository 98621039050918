import Grid from "@mui/material/Grid";
import { LoadingButton as Button } from "@mui/lab";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useState } from "react";
import api from "../api";
import { STATUS, Status, Tenant } from "../App";
import { endpoints } from "../utils/endpoints";
import { delJWT } from "../utils/cookies";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ButtonContent from "./ButtonContent";

interface LoggendInViewActivationProps {
  setStatus: (status: Status) => void;
  selectedTenant: Tenant | null;
  showLogOutButton: boolean;
}

export default function LoggedInViewActivation({
  setStatus,
  selectedTenant,
  showLogOutButton,
}: LoggendInViewActivationProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogout = () => {
    setLoading(true);
    api
      .post(endpoints.logout)
      .then(() => {
        delJWT();
        setStatus(STATUS.UNAUTHORIZED);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={"bold"} align="center">
          {selectedTenant?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          size="large"
          sx={{
            padding: "8px",
            height: 38,
            display: "flex",
            justifyContent: "space-between",
            textTransform: 'none',
          }}
          onClick={() => {
            window.location.href = selectedTenant?.tar_endpoint ?? '';
          }}
          loading={loading}
        >
          <ButtonContent 
            StartIcon={<div />} 
            buttonText={"Tovább a tanulmányi rendszerre"} 
            EndIcon={<LoginIcon />}
          />
        </Button>
        </Grid>
        <Grid item xs={12}>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          size="large"
          sx={{
            padding: "8px",
            height: 38,
            display: "flex",
            justifyContent: "space-between",
            textTransform: 'none',
          }}
          onClick={() => {
            window.location.href = selectedTenant?.epreg_endpoint ?? '';
          }}
          loading={loading}
        >
          <ButtonContent 
            StartIcon={<div />} 
            buttonText={"Tovább az EP Regisztrációs Rendszerre"} 
            EndIcon={<LoginIcon />}
          />
        </Button>
      </Grid>
      {showLogOutButton ?
        <Grid item xs={12}>
          <Button
            fullWidth
            color="secondary"
            size="large"
            sx={{
              background: "#F5F5F5",
              padding: "8px",
              height: 38,
              display: "flex",
              justifyContent: "space-between",
              textTransform: 'none',
            }}
            loadingPosition="end"
            loading={loading}
            onClick={handleLogout}
          >
          <ButtonContent 
            StartIcon={<div />} 
            buttonText={"Kijelentkezés"} 
            EndIcon={<LogoutIcon />}
          />
          </Button>
        </Grid>
      :
      <Grid item xs={12}>
        <Button
          fullWidth
          color="secondary"
          size="large"
          sx={{
            background: "#F5F5F5",
            padding: "8px",
            height: 38,
            display: "flex",
            justifyContent: "space-between",
            textTransform: 'none',
          }}
          loadingPosition="end"
          loading={loading}
          onClick={() => setStatus(STATUS.TENANT_SELECT)}
        >
          <ButtonContent 
            StartIcon={<ArrowBackIosIcon />} 
            buttonText={"Vissza"} 
            EndIcon={<div />}
          />
        </Button>
      </Grid>
    }
    </Grid>
  );
}
